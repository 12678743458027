<template>
    <b-container class="c-page">
        <div class="c-page__title">
            <div>
                <h1>{{ $t('projects.title') }}</h1>
            </div>
        </div>
        <div v-if="isAdmin" class="row">
            <div class="c-page__table-scrollable-wrap">
                <table class="table c-page__table">
                    <thead>
                    <tr>
                        <th class="c-page__table-control-col">#</th>
                        <th>{{$t('projects.tableColDate')}}</th>
                        <th><b-input @keyup="setFilterCol('call')" v-model="filter.call" :placeholder="$t('projects.tableColCall')" ></b-input></th>
                        <th><b-input @keyup="setFilterCol('user_name')" v-model="filter.user_name" :placeholder="$t('projects.tableColUserName')"></b-input></th>
                        <th><b-input @keyup="setFilterCol('name')" v-model="filter.name" :placeholder="$t('projects.tableColName')"></b-input></th>
                        <th colspan="2">
                            <b-select @change="setFilterCol('state')" v-model="filter.state">
                                <b-form-select-option value="">{{$t('statesShortcut.all_filter')}}</b-form-select-option>
                                <b-form-select-option v-for="state in states" :key="'select_'+state" :value="state">{{$t('statesShortcut["'+state+'"]')}}</b-form-select-option>
                            </b-select>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="item in filteredData" >
                        <tr :key="item.id" >
                            <td class="c-page__table-control-col">
                                <b-icon v-if="item.visible" icon="eye-fill" variant="info" ></b-icon>
                                <b-icon v-else icon="eye-slash" variant="info" ></b-icon>
                            </td>
                            <td>
                                {{item.init_date.date | moment("DD/MM/YYYY") }}
                            </td>
                            <td class="text-center">
                                {{item.number_of_call}}
                            </td>
                            <td>
                                <div class="text-clamp-3">{{item.user_name}}</div>
                            </td>
                            <td>
                                <router-link :to="{name: 'projectsPreview', params: {id: item.id}}">
                                    {{item['name_'+$i18n.locale]}}
                                </router-link>
                            </td>
                            <td>
                                <span v-if="item.status!=''">
                                    {{$t('statesBtn.'+item.status)}}
                                </span>
                            </td>
                            <td class="text-right c-page__table-edit">
                                <router-link :to="{name: 'projectsPreview', params: {id: item.id}}">
                                    <b-icon class="clickable" icon="pencil-square" variant="info" ></b-icon>
                                </router-link>
                            </td>
                        </tr>
                    </template>
                    </tbody>
                </table>
            </div>
        </div>
        
        <!-- user  -->
        
        <div v-else class="row">
            <div v-for="item in posts" :key="item.id" class="col-12 col-md-6 col-lg-4 mb-4">
                <b-card body-class="c-card-body">
                    <i>{{ item['name_'+$i18n.locale] }}</i>
                    
    
                    <div v-if="item.status != ''">
                        <hr class="w-100">
                        <h3>Status:</h3>
                        <p>{{$t('statesBtn.'+item.status)}}</p>
                    </div>
                    <hr class="w-100">
                    <template v-if="item.phase == 1">
                        <div class="c-card-body__wrap" :class="{'c-card-body__wrap--disabled' : item.edit_locked }">
                            <label>{{ $t('projects.phase_1') }}</label>
                            <router-link :to="{name: 'projectsEdit', params: {id: item.id}}" :title="$t('universalTxt.edit')">
                                <b-button variant="primary" size="sm" >
                                    <b-icon class="clickable" icon="pencil-square" variant=""></b-icon>
                                    <span>&nbsp;{{ $t('universalTxt.edit') }}</span>
                                </b-button>
                            </router-link>
                            
                        </div>
                        <hr class="w-100">
                        <div class="c-card-body__wrap" :class="{'c-card-body__wrap--disabled' : item.edit_locked }">
                            <label>{{ $t('projects.phase_2') }}</label>
                            <b-button variant="primary" size="sm" @click="exportForm(item.id)" >
                                <b-icon icon="download"></b-icon>&nbsp;
                                {{ $t('projects.downloadBtn') }}
                            </b-button>
                        </div>
                        <div class="c-card-body__wrap" >
                            <hr class="w-100">
                            <label>{{ $t('projects.phase_3') }}</label>
                            <router-link :to="{name: 'projectsAttachments', params: {id: item.id}}">
                                <b-button variant="primary" size="sm" >{{ $t('projects.attachments') }}</b-button>
                            </router-link>
                        </div>
                        <hr class="w-100">
                        <div class="c-card-body__wrap" :class="{'c-card-body__wrap--disabled' : item.edit_locked }">
                            <label>{{ $t('projects.phase_4') }}</label>
                            <b-btn v-if="item.status == 'correction_1'" variant="primary" size="sm" @click="setState('fix_1', item.id, 1)">
                                {{ $t('projects.checkProjectBtn1') }}
                            </b-btn>
                            <b-btn v-else-if="item.status == 'correction_2'" variant="primary" size="sm" @click="setState('fix_2', item.id, 1)">
                                {{ $t('projects.checkProjectBtn2') }}
                            </b-btn>
                            <b-btn v-else variant="primary" size="sm" @click="setState('registered', item.id, 1)">
                                {{ $t('projects.checkProjectBtn') }}
                            </b-btn>
                        </div>
                    </template>
                    
                    <template v-else-if="item.phase == 2 && item.status != 'p2_accepted'">
                        <div class="c-card-body__wrap" :class="{'c-card-body__wrap--disabled' : item.edit_locked }">
                            <label>{{ $t('projects.phase_2_1') }}</label>
                            <router-link :to="{name: 'projectsEdit', params: {id: item.id}}" :title="$t('universalTxt.edit')">
                                <b-button variant="primary" size="sm" >
                                    <b-icon class="clickable" icon="pencil-square" variant=""></b-icon>
                                    <span v-if="item.name_cs != ''">&nbsp;{{ $t('universalTxt.edit_final') }}</span>
                                </b-button>
                            </router-link>
                        </div>
                        <hr class="w-100">
                        <div class="c-card-body__wrap" :class="{'c-card-body__wrap--disabled' : item.edit_locked }">
                            <label>{{ $t('projects.phase_2_2') }}</label>
                            <b-button variant="primary" size="sm" @click="exportForm2(item.id)" >
                                <b-icon icon="download"></b-icon>&nbsp;
                                {{ $t('projects.downloadBtn') }}
                            </b-button>
                        </div>
                        <div class="c-card-body__wrap" >
                            <hr class="w-100">
                            <label>{{ $t('projects.phase_2_3') }}</label>
                            <router-link :to="{name: 'projectsAttachments', params: {id: item.id}}">
                                <b-button variant="primary" size="sm" >{{ $t('projects.attachments') }}</b-button>
                            </router-link>
                        </div>
                        <hr class="w-100">
                        <div class="c-card-body__wrap" :class="{'c-card-body__wrap--disabled' : item.edit_locked }">
                            <label>{{ $t('projects.phase_2_4') }}</label>
                            <b-btn v-if="item.status == 'p2_correction_1'" variant="primary" size="sm" @click="setState('p2_fix_1', item.id, 2)">
                                {{ $t('projects.checkProjectBtn1') }}
                            </b-btn>
                            <b-btn v-else variant="primary" size="sm" @click="setState('p2_registered', item.id, 2)">
                                {{ $t('projects.checkProjectBtn') }}
                            </b-btn>
                        </div>
                    </template>
                    
                    <template v-else>
                        <div class="c-card-body__wrap" >
                            <router-link :to="{name: 'projectsAttachments', params: {id: item.id}}">
                                <b-button variant="primary" size="sm" >{{ $t('projects.documents') }}</b-button>
                            </router-link>
                        </div>
                    </template>
                </b-card>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <b-card body-class="c-card-body c-card-body--new" @click="addItem()">
                    <div class="c-card-body__icon-wrap" :title="$t('projects.newProject')">
                        <b-icon icon="plus-square-fill" variant="primary"
                        ></b-icon>
                    </div>
                </b-card>
            </div>
        </div>
        <router-view @loadData="loadData()"></router-view>
    </b-container>

</template>

<script>

export default {
    name: 'projects',
    components: {},
    data() {
        return {
            posts: [],
            sortByFilter: '',
            sortByData: '',
            filter: {
                call: '',
                name: '',
                user_name:'',
                state: '',
            }
        }
    },
    methods: {
        async loadData() {
            try {
                const response = await this.$http({
                    url: process.env.VUE_APP_SERVER + 'api/articles/list.php',
                    headers: {'content-type': 'application/x-www-form-urlencoded'},
                    method: 'GET'
                });
                
                this.posts = response.data;
                
            } catch (error) {
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', 'no data');
            }
            
        },
        async addItem() {
            
            try {
                const response = await this.$http({
                    url: process.env.VUE_APP_SERVER + 'api/articles/add-item.php',
                    headers: {'content-type': 'application/x-www-form-urlencoded'},
                    method: 'POST'
                });
                
                await this.$router.push({name: 'projectsEdit', params: {id: response.data.id}});
                await this.loadData();
                
            } catch (error) {
                await this.loadData();
                console.log("error", error);
            }
            
        },
        async setState(status, id, phase) {
            if (status == 'registered' || status == 'fix_1' || status == 'fix_2') {
                if (!confirm(this.$t('projects.confirmText'))) {
                    return false;
                }
            }
            try {
                await this.$http({
                    url: process.env.VUE_APP_SERVER + 'api/articles/set-state.php',
                    headers: {'content-type': 'application/x-www-form-urlencoded'},
                    data: {
                        id: id,
                        status: status,
                        phase: phase
                    },
                    method: 'POST'
                });
    
                
                this.$eventHub.$emit('openMessageController', 'success', this.$t('projects.messageSend'));
                await this.loadData();
                
            } catch (error) {
                await this.loadData();
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
            }
        
        },
        async exportForm(projectId) {
            try {
                const response = await this.$http({
                    url: process.env.VUE_APP_SERVER + 'api/articles/export-pdf.php',
                    headers: {'content-type': 'application/x-www-form-urlencoded'},
                    data: { id: projectId },
                    method: 'POST'
                });
    
                const link = document.createElement('a');
                link.href = process.env.VUE_APP_SERVER + response.data.data;
                link.download = '';
                link.target = '__blank';
                link.click();
                
        
            } catch (error) {
                await this.loadData();
                console.log("error", error);
            }
        },
        async exportForm2(projectId) {
            try {
                const response = await this.$http({
                    url: process.env.VUE_APP_SERVER + 'api/articles/export-2-pdf.php',
                    headers: {'content-type': 'application/x-www-form-urlencoded'},
                    data: { id: projectId },
                    method: 'POST'
                });
            
                const link = document.createElement('a');
                link.href = process.env.VUE_APP_SERVER + response.data.data;
                link.download = '';
                link.target = '__blank';
                link.click();
            
            
            } catch (error) {
                await this.loadData();
                console.log("error", error);
            }
        },
        setFilterCol(type) {
            if (type=='call') {
                this.sortByFilter = 'call';
                this.sortByData = 'number_of_call';
            }
            else if (type=='name') {
                this.sortByFilter = 'name';
                this.sortByData = 'name_'+this.$i18n.locale;
        
            }
            else if (type=='user_name') {
                this.sortByFilter = 'user_name';
                this.sortByData = 'user_name';
        
            }
            else if (type=='state') {
                this.sortByFilter = 'state';
                this.sortByData = 'status';
    
            }
            
            for (let item in this.filter) {
                if (type != item) {
                    this.filter[item] = '';
                }
            }
            
            
        }
    },
    computed: {
        filteredData() {
            let result;
            if (this.posts.length != 0 && this.sortByData != '') {
                result = this.posts.filter(item => {
                    let filter = this.filter[this.sortByFilter].toString().toLowerCase();
                    return item[this.sortByData].toString().toLowerCase().indexOf(filter) > -1
                })
            } else {
                result = this.posts;
            }
            return result;
            
        },
        isAdmin: function () {
            try {
                return (this.$store.getters.user.permission == 'admin') ? true : false
            } catch (err) {
                return false
            }
        },
        states() {
            let states = this.$store.getters.states;
            let states2 = this.$store.getters.states2;
            let tempStates1 = states.user.concat(states.admin);
            let tempStates2 = states2.user.concat(states2.admin);
            return tempStates1.concat(tempStates2);
        },
    },
    mounted() {
        this.loadData();
        
    }
}
</script>

<style lang="scss" scoped>
.c-card-body {
    min-height: 400px;
    
    &--new {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        display: flex;
        cursor: pointer;
        
        &:hover {
            .c-card-body__icon-wrap {
                transform: scale(1.1);
            }
        }
    }
    
    &__icon-wrap {
        font-size: 45px;
        transition: transform 250ms;
    }
    &__wrap {
        &--disabled {
            pointer-events: none;
            opacity: .4;
            user-select: none;
        }
    }
    label {
        width: 100%;
    }
}
.text-clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    
}
</style>
